










































































import { buildUrlQueryList } from "@/builder";
import { Row, useBlob, useProductCategory } from "@/hooks";
import MQueryPage from "@/mixins/MQueryPage.vue";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import {
  DEFAULT_PAGE,
  PAGE_SIZE_OPTIONS,
} from "@/models/constant/global.constant";
import { ProductCategoryReportResponseDto } from "@/models/interface/ProductCategoryReport.interface";
import { Component, Mixins } from "vue-property-decorator";

const ONE = 1;

@Component
export default class IndexPage extends Mixins(MQueryPage) {
  PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS;

  loading = {
    find: false,
    download: false,
  };

  dataSource: Row<ProductCategoryReportResponseDto, string>[] = [];
  columns = [
    {
      title: this.$t("lbl_part_category"),
      dataIndex: "name",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_created_by"),
      dataIndex: "createdBy",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_created_date"),
      dataIndex: "createdDate",
      scopedSlots: { customRender: "date" },
    },
    {
      title: this.$t("lbl_modified_by"),
      dataIndex: "modifiedBy",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_modified_date"),
      dataIndex: "modifiedDate",
      scopedSlots: { customRender: "date" },
    },
    {
      title: this.$t("lbl_action"),
      scopedSlots: { customRender: "operation" },
      align: "center",
    },
  ];

  created(): void {
    this.findCategory();
  }

  fetchCategory(params: RequestQueryParams): void {
    const { findAllRemastered, toTableRows } = useProductCategory();
    this.loading.find = true;
    findAllRemastered(params)
      .then(res => {
        this.dataSource = toTableRows(res.data);
        this.pagination.totalElements = res.totalElements;
      })
      .finally(() => {
        this.loading.find = false;
      });
  }

  findCategory(): void {
    const { searchBy } = useProductCategory();
    const params = new RequestQueryParams();
    params.search = searchBy({ name: this.pagination.search });
    params.limit = this.pagination.limit;
    params.page = this.pagination.page - ONE;
    this.fetchCategory(params);
  }

  handleSearch(): void {
    this.resetCurrentPage();
    this.findCategory();
    this.updateQueryRoute();
  }

  handleDownload(): void {
    const { download, searchBy, buildParamDownload } = useProductCategory();
    const { toDownload } = useBlob();
    this.loading.download = true;

    const params = new RequestQueryParams();
    params.search = searchBy({ name: this.pagination.search });
    params.limit = this.pagination.totalElements;
    params.params = buildParamDownload();

    download(params)
      .then(res => {
        toDownload(res, "master_part_category.xlsx");
      })
      .finally(() => {
        this.loading.download = false;
      });
  }

  onChangeTable(pagination): void {
    const { current, pageSize } = pagination;

    this.pagination.page =
      pageSize !== this.pagination.limit ? DEFAULT_PAGE : current;
    this.pagination.limit = pageSize;

    this.findCategory();
    this.updateQueryRoute();
  }

  updateQueryRoute(): void {
    this.$router.replace({
      name: this.$route.name || "",
      query: {
        ...this.$route.query,
        ...buildUrlQueryList({
          limit: this.pagination.limit,
          search: this.pagination.search,
          page: this.pagination.page,
        }),
      },
    });
  }

  handleReset(): void {
    this.pagination.search = "";

    this.resetCurrentPage();
    this.findCategory();
    this.updateQueryRoute();
  }
}
